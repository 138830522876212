import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Drawer,
  Typography,
  Avatar,
  message,
  Popconfirm,
  Tooltip,
  Spin,
} from 'antd';
import './Restaurant.css';
import Back from '../../assests/Back.svg';
import employees from '../../assests/employees.svg';
import orderprocess from '../../assests/orderprocess.svg';
import orders from '../../assests/orders.svg';
import payment from '../../assests/payment.svg';
import persons from '../../assests/persons.svg';
import printers from '../../assests/printers.svg';
import qrcode from '../../assests/qrcode.svg';
import serveys from '../../assests/serveys.svg';
import stations from '../../assests/stations.svg';
import tables from '../../assests/tables.svg';
import users from '../../assests/users.svg';
import category from '../../assests/category.svg';
import cfd from '../../assests/cfd.svg';
import layout from '../../assests/layout.svg';
import branding from '../../assests/branding.svg';
import { falseyValueCases, ImageEnv } from '../CommonLogics/CommonMethods';
import Delete from '../../assests/Delete.svg';
import Edit from '../../assests/Edit.svg';
import {
  getRestaurant,
  deleteRestaurant,
  getRestaurantById,
} from '../../Actions/RestaurantAction';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
const RestaurantDetails = (props) => {
  const {
    currentPage,
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    // restaurantDetailId,
    // editId
  } = props;
  const navigate = useNavigate();
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const handleCloseDrawer = () => {
    navigate('/restaurants');
    // setDrawerVisible(false);
  };
  const { restaurant, isRestaurantByIdLoading } = useSelector((state) => {
    const { restaurant, isRestaurantByIdLoading } = state.restaurant;
    return {
      restaurant,
      isRestaurantByIdLoading,
    };
  });

  useEffect(() => {
    dispatch(getRestaurantById(orgId));
    localStorage.setItem('organization_id', orgId);
  }, []);

  const restaurantDetailId = restaurant?.organizations_detail?.id;
  const { orgId } = useParams();

  const onHandleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Spin spinning={isRestaurantByIdLoading}>
      <Drawer
        closeIcon={<img src={Back} alt='props' />}
        closable={false}
        title={
          <div>
            <Row>
              <Col span={2}>
                <img
                  src={Back}
                  alt='props'
                  onClick={handleCloseDrawer}
                  style={{ cursor: 'pointer' }}
                />
              </Col>
              <Col span={16}>
                <Typography className='drawerHeading'>Show</Typography>
              </Col>
            </Row>
          </div>
        }
        placement='right'
        onClose={handleCloseDrawer}
        open={!falseyValueCases(drawerVisible) ? drawerVisible : true}
        width={width > 400 ? '400px' : '100%'}
      >
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={employees} alt='props' />}
              onClick={() =>
                onHandleNavigate(`/restaurants/${orgId}/employees`)
              }
            >
              Employees
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={persons} alt='props' />}
              onClick={() =>
                onHandleNavigate(`/restaurants/${orgId}/customers`)
              }
            >
              Customers
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={stations} alt='props' />}
              onClick={() => onHandleNavigate(`/restaurants/${orgId}/stations`)}
            >
              Stations
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={orderprocess} alt='props' />}
              onClick={() =>
                onHandleNavigate(`/restaurants/${orgId}/orderProcess`)
              }
            >
              Order Processes
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={category} alt='props' />}
              onClick={() => onHandleNavigate(`/restaurants/${orgId}/category`)}
            >
              Categories
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={payment} alt='props' />}
              onClick={() =>
                onHandleNavigate(`/restaurants/${orgId}/paymentMode`)
              }
            >
              Payment Modes
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={printers} alt='props' />}
              // onClick={() => onHandleNavigate("/printer")}
            >
              Printers
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={users} alt='props' />}
              onClick={() =>
                onHandleNavigate(`/restaurants/${orgId}/userthemes`)
              }
            >
              User Themes
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={qrcode} alt='props' />}
              onClick={() => onHandleNavigate(`/restaurants/${orgId}/qrCodes`)}
            >
              QR Codes
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={orders} alt='props' />}
            >
              Orders
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={tables} alt='props' />}
              onClick={() => onHandleNavigate(`/restaurants/${orgId}/tables`)}
            >
              Tables
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={serveys} alt='props' />}
              onClick={() => onHandleNavigate(`/restaurants/${orgId}/survey`)}
            >
              Surveys
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={cfd} alt='props' />}
              onClick={() =>
                onHandleNavigate(`/restaurants/${orgId}/cfdimages`)
              }
            >
              CFD Images
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={layout} alt='props' />}
              onClick={() => onHandleNavigate(`/restaurants/${orgId}/layout`)}
            >
              Layout
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className='detailsButton'
              icon={<img src={branding} alt='props' />}
              onClick={() => onHandleNavigate(`/restaurants/${orgId}/branding`)}
            >
              Branding
            </Button>
          </Col>
        </Row>

        {restaurant && (
          <div>
            <Avatar
              shape='square'
              size={95}
              className='image'
              src={restaurant.org_logo && ImageEnv(restaurant.org_logo)}
            >
              <Typography style={{ fontSize: '20px', color: 'white' }}>
                {restaurant?.name?.[0]}
              </Typography>
            </Avatar>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>
                  Restaurant Name{' '}
                </Typography>
                {/* </Col>
              <Col span={24}> */}
                <Typography className='detailsubheading'>
                  {' '}
                  {restaurant?.name}
                </Typography>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>
                  Legal Entity Name{' '}
                </Typography>
                <Typography className='detailsubheading'>
                  {restaurant?.legal_entity_name}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Address</Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {restaurant?.organizations_detail?.address_line_1}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Email</Typography>
                <Typography className='detailsubheading'>
                  {restaurant?.organizations_detail?.email}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Created at</Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {restaurant?.type}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Dine-in</Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {restaurant?.is_dine_in
                    ? 'true'
                      ? 'yes'
                      : 'false'
                    : 'no'}{' '}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Takeaway </Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {restaurant?.is_take_away
                    ? 'true'
                      ? 'yes'
                      : 'false'
                    : 'no'}{' '}
                </Typography>
              </Col>
            </Row>
          </div>
        )}
      </Drawer>
    </Spin>
  );
};
export default RestaurantDetails;
