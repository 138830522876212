import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Breadcrumb,
  Select,
} from 'antd';
import { Stage, Layer, Rect, Text, Circle, Group } from 'react-konva';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getTables,
  deleteTables,
  getTablesById,
  updateTables,
} from '../../Actions/TableAction';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import { max, times, toNumber, omit } from 'lodash';
import TableModalForm from './TableModalForm';
import babyChair from '../../assests/babyChair.svg';
import babyChair2 from '../../assests/babyChair2.svg';
import disabledChair from '../../assests/disabledChair.svg';
import disabledChair2 from '../../assests/disabledChair2.svg';
import barbecueGrill from '../../assests/barbecueGrill.svg';
import barbecueGrill2 from '../../assests/barbecueGrill2.svg';
import { getLayout } from '../../Actions/LayoutAction';
import {
  getTablePositions,
  useDimensions,
} from '../CommonLogics/CommonMethods';

const Tables = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isTableDesigner, setIsTableDesigner] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isTablesloading, restaurant, setRestaurantId, allTables, allLayout } =
    useSelector((state) => {
      const { restaurant, setRestaurantId } = state.restaurant;
      const { allTables, isTablesloading } = state.tables;
      const { allLayout } = state.layout;
      return {
        restaurant,
        isTablesloading,
        setRestaurantId,
        allTables,
        allLayout,
      };
    });
  const { length } = allTables || {};
  const { organization_tables } = allTables || {};
  const { width, height } = useDimensions();

  useEffect(() => {
    dispatch(getTables(`api/organization_tables`));
    dispatch(getLayout(`api/organization_layouts`));
  }, [dispatch]);

  const { orgId } = useParams();

  const searchParamId = searchParams.get('layout');

  const [changedLayout, setChangedLayout] = useState({
    selectedlayout: searchParamId
      ? _.filter(allLayout, (layout) => layout.id === searchParamId)[0]
      : allLayout[0],
    selectedLayoutTable: searchParamId
      ? _.filter(
          organization_tables,
          (table) => table?.organization_layout?.id === searchParamId
        )
      : _.filter(
          organization_tables,
          (table) => table?.organization_layout?.id === allLayout[0]?.id
        ),
  });
  const { selectedlayout, selectedLayoutTable } = changedLayout;

  useEffect(() => {
    setChangedLayout({
      selectedlayout: searchParamId
        ? _.filter(allLayout, (layout) => layout.id === searchParamId)[0]
        : allLayout[0],
      selectedLayoutTable: searchParamId
        ? _.filter(
            organization_tables,
            (table) => table?.organization_layout?.id === searchParamId
          )
        : _.filter(
            organization_tables,
            (table) => table?.organization_layout?.id === allLayout[0]?.id
          ),
    });
    // setSearchParams({
    //   layout: allLayout[0]?.layout_name
    // })
  }, [allLayout, organization_tables]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getTablesById(''));
    dispatch({
      type: 'GET_SELECTED_STATIONS_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleChange = (selectedValue) => {
    {
      selectedValue === 'table_designer'
        ? setIsTableDesigner(true)
        : setIsTableDesigner(false);
    }
  };

  const handleAddTable = () => {
    setIsModalVisible(true);
    setEditId('');
  };

  const handleEditTable = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const successDeleteTable = () => {
    dispatch(getTables(`api/organization_tables`));
    setTimeout(() => {
      message.success(`Your Table Deleted Successfully`);
    }, 1000);
  };

  const options = allLayout?.map((layout, i) => {
    return {
      label: layout.layout_name,
      value: layout.id,
    };
  });
  const handleOpen = (event) => {
    const id = event.target.parent.children[0].attrs.id;
    setEditId(id);
    setIsModalVisible(true);
  };
  const Columns = [
    {
      title: 'Table name',
      dataIndex: 'table_number',
      width: '14%',
      render: (Id, { id, table_number }) => {
        return (
          <Row>
            <Col>
              <Typography>{table_number}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: 'seating_capacity',
      width: '10%',
      render: (seating_capacity) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {seating_capacity}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Layout',
      dataIndex: 'organization_layout',
      width: '10%',
      render: (organization_layout) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {organization_layout?.layout_name}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Kids seat',
      dataIndex: 'kids_seat_availability',
      width: '10%',
      render: (kids_seat_availability) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {kids_seat_availability === true ? (
                  <img src={babyChair} alt='Baby Chair' />
                ) : (
                  <img src={babyChair2} alt='Baby Chair' />
                )}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Disabled Chair',
      dataIndex: 'disabled_seat_availability',
      width: '10%',
      render: (disabled_seat_availability) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {disabled_seat_availability === true ? (
                  <img src={disabledChair} alt='Disabled Chair' />
                ) : (
                  <img src={disabledChair2} alt='Disabled Chair' />
                )}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Barbecue grill',
      dataIndex: 'barbeque_grill_availability',
      width: '10%',
      render: (barbeque_grill_availability) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {barbeque_grill_availability === true ? (
                  <img src={barbecueGrill} alt='Barbeque Grill' />
                ) : (
                  <img src={barbecueGrill2} alt='Barbeque Grill' />
                )}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '6%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEditTable} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteTables]}
                customMethod={successDeleteTable}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  const handleChangeLayout = (id) => {
    const selectedChangedlayout = _.filter(
      allLayout,
      (layout) => layout?.id === id
    )[0];
    setChangedLayout({
      ...changedLayout,
      selectedlayout: selectedChangedlayout,
      selectedLayoutTable: _.filter(
        organization_tables,
        (table) => table?.organization_layout?.id === id
      ),
    });
    setSearchParams({
      layout: selectedChangedlayout.id,
    });
  };

  const MAX_CORDINATE_WIDTH = max(getTablePositions(selectedLayoutTable, 'x'));
  const MAX_CORDINATE_HEIGTH = max(getTablePositions(selectedLayoutTable, 'y'));

  const onDragStartTableHandler = (event) => {
    const id = event.target.children[0].attrs.id;
    const xWidth = event?.target?.attrs?.x;
    const yWidth = event?.target?.attrs?.y;

    if (xWidth > 0 || yWidth > 0) {
      setChangedLayout({
        ...changedLayout,
        selectedLayoutTable: selectedLayoutTable.map((table, i) => {
          return {
            ...table,
            isDragging: table?.id === id,
          };
        }),
      });
    }
  };
  const onDragEndTableHandler = (event) => {
    const id = event.target.children[0].attrs.id;
    const xWidth = event?.target?.attrs?.x;
    const yWidth = event?.target?.attrs?.y;

    const currentTable = selectedLayoutTable?.map((table, i) => {
      return table?.id === id
        ? {
            ...table,
            x_position:
              table?.id === id
                ? xWidth > 0
                  ? event.target.x()
                  : 35
                : table?.x_position,
            y_position:
              table?.id === id
                ? yWidth > 0
                  ? event.target.y()
                  : 35
                : table?.y_position,
            isDragging: table?.id === id ? false : true,
          }
        : { ...table };
    });

    setChangedLayout(currentTable);
    setChangedLayout({
      ...changedLayout,
      selectedLayoutTable: currentTable,
    });
    const data = omit(
      currentTable?.filter((table) => {
        return table?.id === id;
      })?.[0],
      'isDragging'
    );
    dispatch(updateTables(data, successCallback));
  };

  const successCallback = () => {
    dispatch(getTables(`api/organization_tables`));
  };

  return (
    <div>
      {isModalVisible ? (
        <TableModalForm
          restaurant={restaurant}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          editId={editId}
          setEditId={setEditId}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedlayout={selectedlayout}
          changedLayout={selectedLayoutTable}
          setChangedLayout={setChangedLayout}
          allLayout={allLayout}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          orgId={orgId}
        ></TableModalForm>
      ) : (
        <Spin spinning={isTablesloading}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurantDrawer()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  Tables
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={12}>
              <Typography className='heading'>Tables</Typography>
            </Col>
            <Col xl={4} lg={6} md={10} sm={24} xs={24}>
              {isTableDesigner ? (
                <Select
                  value={selectedlayout?.layout_name}
                  className='tableSelect'
                  style={{ width: '150px' }}
                  showArrow
                  suffixIcon={
                    <CaretDownOutlined
                      style={{ fontSize: '16px', color: '#7B7B7B' }}
                    />
                  }
                  options={options}
                  onChange={handleChangeLayout}
                />
              ) : (
                ''
              )}
            </Col>
            <Col xl={4} lg={6} md={10} sm={12} xs={24}>
              <Select
                defaultValue='Tables Designer'
                className='tableSelect'
                style={{ width: '170px' }}
                showArrow
                suffixIcon={
                  <CaretDownOutlined
                    style={{ fontSize: '15px', color: '#7B7B7B' }}
                  />
                }
                onChange={handleChange}
                options={[
                  {
                    value: 'table_designer',
                    label: 'Table Designer',
                  },
                  {
                    value: 'table_list',
                    label: 'Table List',
                  },
                ]}
              />
            </Col>

            <Col xl={4} lg={6} md={24} sm={24} xs={24}>
              <Row justify='end' gutter={[8, 8]}>
                <Col>
                  <Button
                    className='detailsButton'
                    onClick={handleAddTable}
                    icon={<img src={plus} alt='props' />}
                  >
                    Add Table
                  </Button>
                </Col>
              </Row>
            </Col>
            {isTableDesigner ? (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[24, 24]}>
                  {/* <Col span={8}></Col> */}
                  <Col
                    span={24}
                    style={{
                      width: width - 397,
                      height: height - 250,
                      overflow: 'auto',
                      border: '1px solid #262626',
                    }}
                  >
                    <Stage
                      width={
                        MAX_CORDINATE_WIDTH > width * 0.9
                          ? MAX_CORDINATE_WIDTH * 1.5
                          : width * 1.5
                      }
                      height={
                        MAX_CORDINATE_HEIGTH > height * 0.9
                          ? MAX_CORDINATE_HEIGTH * 1.5
                          : height * 1.5
                      }
                      style={{
                        overflow: 'auto',
                        overflowY: 'auto',
                      }}
                    >
                      <Layer>
                        {selectedLayoutTable?.map((val, i) => {
                          const CapacityArray = times(
                            val.seating_capacity,
                            String
                          );
                          return (
                            <Group
                              x={toNumber(
                                val?.x_position === '0' ? 35 : val?.x_position
                              )}
                              y={toNumber(
                                val?.y_position === '0' ? 35 : val?.y_position
                              )}
                              width={50}
                              height={50}
                              draggable
                              onDragStart={onDragStartTableHandler}
                              onDragEnd={onDragEndTableHandler}
                              onClick={handleOpen}
                              key={i}
                              // onTap={() => onOrgTableOperations(item, 'update')}
                            >
                              {CapacityArray.map((capacity, index) => {
                                const remainingTables =
                                  CapacityArray?.length > 10
                                    ? CapacityArray?.length - 10
                                    : 0;
                                return (
                                  <Group key={index} id={val?.id}>
                                    {index <= 10 ? (
                                      <Circle
                                        key={index}
                                        id={val?.id}
                                        radius={20}
                                        width={20}
                                        height={20}
                                        x={
                                          index > 8
                                            ? 115
                                            : index > 5
                                            ? -15
                                            : index > 2
                                            ? 25 * (index + 1 - 3)
                                            : 25 * (index + 1)
                                        }
                                        y={
                                          index > 8
                                            ? 25 * (index + 1 - 9)
                                            : index > 5
                                            ? 25 * (index + 1 - 6)
                                            : index > 2
                                            ? 115
                                            : -15
                                        }
                                        fill='#262626'
                                      />
                                    ) : (
                                      <Text
                                        text={remainingTables + ' more'}
                                        fontFamily=''
                                        fontWeight=''
                                        fontSize={12}
                                        fontStyle='bold'
                                        x={108}
                                        y={50 * 1.5}
                                        fill='#262626'
                                      />
                                    )}
                                  </Group>
                                );
                              })}
                              <Rect
                                width={100}
                                height={100}
                                fill='#262626'
                                cornerRadius={10}
                                shadowBlur={10}
                                shadowOpacity={0.6}
                              />
                              <Text
                                width={100}
                                height={100}
                                text={val?.table_number}
                                fill='white'
                                align='center'
                                verticalAlign='middle'
                                fontFamily='circular-400'
                                fontSize={16}
                              />
                            </Group>
                          );
                        })}
                      </Layer>
                    </Stage>
                  </Col>
                  {organization_tables && length > 0 ? (
                    <Col span={24}>
                      <Row justify='center'>
                        <Pagination
                          total={organization_tables?.length}
                          onChange={(e) => setCurrentPage(e)}
                          responsive={true}
                          size='large'
                          current={currentPage}
                          showSizeChanger={false}
                        />
                      </Row>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            ) : (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <CustomTable
                      columns={Columns}
                      selectedRow={selectedRow}
                      currentPage={currentPage}
                      setSelectedRow={setSelectedRow}
                      data={organization_tables}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Spin>
      )}
    </div>
  );
};

export default Tables;
